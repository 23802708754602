.rc-tooltip {
  background-color: initial !important;
  z-index: 100002 !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-top-color: var(--backgroundColor) !important;
  border-bottom-color: var(--backgroundColor) !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow,
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  border-left-color: var(--backgroundColor) !important;
  border-right-color: var(--backgroundColor) !important;
}

.rc-tooltip-inner {
  padding: 6px !important;
  color: var(--textColor) !important;
  background-color: var(--backgroundColor) !important;
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: var(--canvasShadow) !important;
  min-height: initial !important;
}

.rc-tooltip-placement-topRight .rc-tooltip-inner {
  text-align: right !important;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 10px !important;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
  text-align: right !important;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 10px !important;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 10px !important;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 10px !important;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 10px !important;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 10px !important;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 10px !important;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 10px !important;
}
