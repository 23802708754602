.fonts-slider {
  align-items: center;
  border-radius: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  position: relative;
  touch-action: none;
  width: 100%;
}

.fonts-slider-handle {
  cursor: grab;
  height: var(--gridTile);
  position: absolute;
  z-index: 1;
  touch-action: pan-x;
  width: var(--gridTile);
}
.fonts-slider-handle:focus {
  outline: none;
}
.fonts-slider .fonts-slider-rail {
  background-color: var(--textColor);
  height: 2px;
  width: 100%;
}
.fonts-slider-track {
  background-color: var(--textColor);
  flex: 1;
  height: 2px;
}

.fonts-slider-vertical .fonts-slider-rail {
  background-color: var(--textColor);
  height: 100%;
  width: 2px;
}
