.Toastify {
  z-index: 400000;
}

.Toastify__toast-container {
  z-index: 9999;
  transform: translate3d(-50%, 0, 9999px);
  position: fixed;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  top: 0;
  left: 50%;
}

.Toastify__toast-container--bottom-center {
  top: auto;
  bottom: var(--gridTile);
}

.Toastify__toast {
  position: relative;
  box-sizing: border-box;
  padding: 6px 11px;
  border-radius: 1px;
  box-shadow: var(--canvasShadow);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
  background-image: linear-gradient(
    45deg,
    var(--toastBackgroundColor1) 0,
    var(--toastBackgroundColor2) 100%
  );
  color: var(--toastColor);
  min-height: var(--gridTile);
  min-width: 320px;
  text-align: center;
  font-size: 0.6875rem;
}

.Toastify__toast + .Toastify__toast {
  margin-top: 1rem;
}

.Toastify__toast--error {
  background-color: var(--confirmationColor);
  background-image: none;
  padding: 0;
}

.Toastify__toast--error.error-toast {
  font-size: 14px;
}

.Toastify__toast--error:not(.error-toast) .Toastify__toast-body {
  padding: 6px 11px;
}

.Toastify__toast--error.error-toast header {
  text-align: left;
  font-size: 16px;
  padding: 16px 11px;
  font-weight: bold;

  background-image: linear-gradient(
    45deg,
    var(--toastBackgroundColor1) 0,
    var(--toastBackgroundColor2) 100%
  );
}

.Toastify__close-button {
  position: absolute;
  right: 11px;
  top: 12px;
  background: transparent;
  color: var(--toastColor);
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.Toastify__toast--error .message {
  text-align: left;
  padding: 14px 11px;
  white-space: pre-wrap;
}

.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1;
  flex: 1;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
