/**
* @TODO remove all these css variables and move color declarations to Theme-UI theme + implement color modes:
* https://theme-ui.com/color-modes
*/

:root {
  /*=============================================
  = Theme independent variables                 =
  =============================================*/

  /*----------  General purpose variables  ----------*/

  /**
   * TODO: refactor other CSS variables to use these variables, to avoid using
   * component-specific variables out of their scope, e.g. --modalBox
   */

  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black: #000;

  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #198754;
  --teal: #20c997;
  --cyan: #0dcaf0;

  --primary: #d0d600;
  --secondary: #ececec;
  --text: #1d1d1b;
  --muted: #c6c6c5;
  --disabled: #d9d9d9;
  --background: #ffffff;
  --background-100: #f5f5f5;
  --warning: #e84e1b; /* TODO: warning and danger are basically the same */
  --danger: #ca1e2b;

  /*----------  Component-specific variables  ----------*/

  --staticGridTile: 2rem;
  --gridTile: calc(var(--staticGridTile) * var(--uiScale));
  --drawerWidth: calc(var(--gridTile) * 12 + 4rem);
  --dynamicFontSize: calc(var(--gridTile) / 3.2);
  --iconColor: #231f20;
  --iconColor__active: #ffffff;
  --iconColor__disabled: #cccccc;
  --iconShadow: rgba(0, 0, 0, 1);
  --iconShadowInverted: rgba(255, 255, 255, 1);
  --transparentFillIcon: #000000;
  --transparentFillSlope: #cccccc;
  --toolBackgroundColor1: #403f41;
  --toolBackgroundColor2: #231f20;
  --toolbarBorder: #e2e2e2;
  --toolbarDivider: #000000;

  --mixedValuesPlaceholder: #666;
  --mixedValuesPlaceholderActive: #c6c6c5;

  --buttonIconFocus: rgba(0, 0, 0, 0.2);

  --buttonDisabledBackground: #d9d9d9;
  --buttonDisabledBackgroundText: #9c9b9b;

  --buttonSecondaryDisabledBackground: #ececec;
  --buttonSecondaryDisabledBackgroundText: #b1b1b1;

  --modalLine: #b3b1b0;
  --modalSubTitle: #595756;
  --modalBox: #f5f5f5;

  --toggleBackground: rgba(0, 0, 0, 0.25);

  --basicDropdownBackground: #ffffff;

  --inputBorder: #808080;
  --inputPlaceholder: #808080;

  --multiInputItemBackground: #ececec;

  --toastBackgroundColor1: #404040;
  --toastBackgroundColor2: #202020;
  --toastColor: #eee;

  --contextBgColor: #fff;

  --contextGradientColor1: #ffffff;
  --contextGradientColor2: #f5f5f5;
  --contextActiveColor: #000000;
  --contextActiveColor_alternative: #ffffff;

  --settingsBorder: #dadcdb;
  --textColor: #000000;
  --invertedTextColor: #ffffff;
  --greyTextColor: #575756;
  --backgroundColor: #ffffff;
  --backgroundColorRGB: 255, 255, 255;
  --textColorRGB: 0, 0, 0;
  --buttonToggleColor__on: #ffffff;
  --buttonToggleColor__off: #000000;
  --buttonToggleColor__hovered: #000000;
  --buttonToggleBackground__on: #000000;
  --buttonToggleBackground__off: transparent;
  --buttonToggleBackground__hovered: #ececec;

  --separator: #e6e6e6;
  --separator__inverted: #666666;

  --confirmationColor: #ca1e2b;

  --canvasShadow: 0 0 10px rgba(0, 0, 0, 0.15);
  --overlayShadow: 0 4px 10px rgba(0, 0, 0, 0.33);
  --controlShadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  --controlShadowTop: 4px -4px 4px rgba(0, 0, 0, 0.15);

  --sidebarTransition: transform 225ms cubic-bezier(0.7, 0, 0.3, 1);
}

body.dark {
  --iconColor: #f2f2f2;
  --iconColor__active: #000000;
  --iconColor__disabled: #4e4f51;
  --iconShadow: rgba(255, 255, 255, 1);
  --iconShadowInverted: rgba(0, 0, 0, 1);
  --transparentFillIcon: #cccccc;
  --transparentFillSlope: #000000;
  --toolBackgroundColor1: #f2f2f2;
  --toolBackgroundColor2: #d4d7d6;
  --toolbarBorder: #555555;
  --toolbarDivider: #ffffff;

  --mixedValuesPlaceholder: #c6c6c5;
  --mixedValuesPlaceholderActive: #666;

  --buttonIconFocus: rgba(255, 255, 255, 0.2);

  --buttonDisabledBackground: #9c9b9b;
  --buttonDisabledBackgroundText: #7f7f7f;

  --buttonSecondaryDisabledBackground: #b1b1b1;
  --buttonSecondaryDisabledBackgroundText: #868686;

  --modalLine: #ffffff;
  --modalSubTitle: #dbd9d8;
  --modalBox: #404040;

  --toggleBackground: rgba(255, 255, 255, 0.25);

  --basicDropdownBackground: #868686;

  --inputBorder: #c6c6c5;
  --inputPlaceholder: #c6c6c5;

  --multiInputItemBackground: #868686;

  --toastBackgroundColor1: #fff;
  --toastBackgroundColor2: #eee;
  --toastColor: #555;

  --contextBgColor: #000000;

  --contextGradientColor1: #414042;
  --contextGradientColor2: #231f20;
  --contextActiveColor: #ffffff;
  --contextActiveColor_alternative: #000000;

  --settingsBorder: #3e3e40;
  --textColor: #ffffff;
  --invertedTextColor: #000000;
  --greyTextColor: #575756;
  --backgroundColor: #000000;
  --backgroundColorRGB: 0, 0, 0;
  --textColorRGB: 255, 255, 255;
  --buttonToggleColor__on: #000000;
  --buttonToggleColor__off: #ffffff;
  --buttonToggleColor__hovered: #ffffff;
  --buttonToggleBackground__on: #ffffff;
  --buttonToggleBackground__off: transparent;
  --buttonToggleBackground__hovered: #404042;

  --separator: #333333;
  --separator__inverted: #f2f2f2;

  --confirmationColor: #ca1e2b;
}

@media screen and (min-width: 1216px) and (min-height: 1024px) {
  :root {
    --gridTile: calc(2.5rem * var(--uiScale));
  }
}

@media screen and (min-width: 1980px) and (min-height: 1200px) {
  :root {
    --gridTile: calc(3.75rem * var(--uiScale));
  }
}

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"),
    local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

html,
body {
  height: 100%;
}

canvas {
  touch-action: none !important;
  -ms-touch-action: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  border-radius: 0;
}

#root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.disable-selection-highlight::selection {
  background: transparent;
}
.disable-selection-highlight::-moz-selection {
  background: transparent;
}
