@keyframes background-swing-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes chrome-loading {
  0% {
    stroke-dasharray: 0 100;
    stroke-dashoffset: 25;
  }
  25% {
    stroke-dasharray: 75 25;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 0 100;
    stroke-dashoffset: -125;
  }
  75% {
    stroke-dasharray: 75 25;
    stroke-dashoffset: -150;
  }
  100% {
    stroke-dasharray: 0 100;
    stroke-dashoffset: -275;
  }
}

@keyframes heartbeat-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  95% {
    transform: scale(0.9);
  }
}

@keyframes pulse-animation {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

@keyframes blink-animation {
  0% {
    border-color: rgba(255, 0, 0, 1);
  }
  50% {
    border-color: rgba(255, 0, 0, 0);
  }
}

@keyframes fade-in-animation {
  0% {
    transform: scale(0.98);
    opacity: 0.2;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in-out-animation {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes highlight-animation {
  5% {
    background-color: var(--accent);
  }
  25% {
    background-color: var(--accent);
  }
  100% {
    background-color: initial;
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
